<template>
  <div class="container">
    <van-tabs v-model="active" color="#1989fa" @change="changeActive">
      <van-tab title="待审核">
        <van-list
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="getList"
        >
          <div class="diyCell" v-for="item in list" :key="item.id" >
            <div class="top">

                <div class="title van-ellipsis">{{ item.realName }}</div>
              <!-- <van-tag size="medium" color="#fdf6ec" text-color="#e6a28b">{{ item.categoryName }}</van-tag> -->
            </div>
            <div class="inner">
              <div class="left">
                <p>街道：{{ item.streetName }}</p>
                <p>小区：{{ item.estateName }}</p>
                <p>房产：{{ item.fullRoomName }}</p>
              </div>
              <van-button type="primary" size="small" @click="showPop(item.id)">审核</van-button>
            </div>
            

            <div class="time">
              <span>入住时间：{{ item.checkInTime }}</span>
              <van-icon name="arrow" />
            </div>
          </div>
        </van-list>
        <van-dialog v-model="popShow" title="审核" show-cancel-button @confirm="confirmRemark">
          <div class="dialog-in two">
            <div class="row top">
              <!-- <div class="label ">是否通过</div> -->
              <div style="width: 100%;">
                <van-radio-group v-model="isVerify" direction="horizontal">
                  <van-radio :name="1" shape="square">通过</van-radio>
                  <van-radio :name="0" shape="square">拒绝</van-radio>
                </van-radio-group>
              </div>
            </div>
            <div class="row top" v-if="isVerify != 1">
              <div class="label ">原因</div>
              <div style="width: 100%">
                <van-field rows="4" v-model="reason" type="textarea"/>
              </div>
            </div>
        
          </div>
        </van-dialog>
        <!-- <van-popup v-model="popShow">
          <div class="dialog">
            <div class="label">拒绝原因：</div>
            <div style="width: 100%">
              <van-field rows="4" v-model="reason" type="textarea"/>
            </div>
            <div class="btns">
              <div class="btn l">通过</div>
              <div class="btn r">拒绝</div>
            </div>
          </div>
        </van-popup> -->
      </van-tab>
      <van-tab title="已审核">
        <van-list
          v-model="loading2"
          :finished="finished2"
          finished-text="没有更多了"
          @load="getList2"
        >
          <div class="diyCell" v-for="item in list2" :key="item.id" >
            <div class="top">
              <div class="title van-ellipsis">{{ item.realName }}</div>

              <!-- <van-tag size="medium" color="#fdf6ec" text-color="#e6a28b">{{ item.categoryName }}</van-tag> -->
            </div>
            
            <p>街道：{{ item.streetName }}</p>
            <p>小区：{{ item.estateName }}</p>
            <p>房产：{{ item.fullRoomName }}</p>
            <div class="time">
              <span>入住时间：{{ item.checkInTime }}</span>
              <van-icon name="arrow" />
            </div>
          </div>
        </van-list>

      </van-tab>
    </van-tabs>
  </div>
</template>

<script>  

import { myVerifyList, myVerifyList2, subVerify } from '@/api/houseApi.js'
export default {
  name: 'memberVerifyList',
  components: {
  }, 
  data() {
    return {
      active: '待审核',
      
      list: [],
      page: 1,
      currentList: -1,
      loading: false,
      finished: false,
      list2: [],
      page2: 1,
      currentList2: -1,
      loading2: false,
      finished2: false,
      popShow: false,
      isVerify: 1,
      vid: '',
      reason: ''
    }
  },
  // 计算属性
  computed: { 
  },

  created() {
    // this.getList()
  },  
  methods: {
    confirmRemark() {
      subVerify({
        checkStatus: this.isVerify,
        id: this.vid,
        reason: this.isVerify == 1?'':this.reason
      }).then(res => {
        if(res.code == 200) {
          // this.active == '待审核'?this.getList():this.getList2()
          this.list = []
          this.currentList = -1
          this.list2 = []
          this.currentList2 = -1
          this.getList()
          this.getList2()
          this.$toast({
            type: 'success',
            message: '操作成功',
            duration: 1000,
          })
        }
      })
    },
    showPop(id) {
      this.vid = id
      this.isVerify = 1
      this.reason = ''
      this.popShow = true
    },
    changeActive() {
    },
    getList() {
      if (this.currentList !== 0) {
        // eventType： 0事件上报 1民事调解 2居民服务申请 3警情上报
        myVerifyList({
          current: this.page,
          size: 10,
        }).then(res => {
          if (res.code === 200) {
            this.loading = false
            this.currentList = res.data ? res.data.length : 0
            this.list = this.list.concat(res.data)
            this.page++ 
          }
        })
      } else {
        this.finished = true; 
      }
    },
    getList2() {
      if (this.currentList2 !== 0) {
        // eventType： 0事件上报 1民事调解 2居民服务申请 3警情上报
        myVerifyList2({
          current: this.page2,
          size: 10,
        }).then(res => {
          if (res.code === 200) {
            this.loading2 = false
            this.currentList2 = res.data ? res.data.length : 0
            this.list2 = this.list2.concat(res.data)
            this.page2++ 
          }
        })
      } else {
        this.finished2 = true; 
      }
    }
  }
}
</script>


<style lang='scss' scoped>
.container {
  width: 100%;
  box-sizing: border-box;
  font-size: 14px;
}
.inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.dialog {
  width: 80vw;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 20px;
  padding: 20px;
  overflow: hidden;
  .btns {
    width: 100%;
    margin-top: 12px;
    display: flex;
    align-items: center;
    .btn {
      width: 50%;
      height: 40px;
      text-align: center;
      line-height: 40px;
      box-sizing: border-box;
    }
    .btn.l {
      background-color: #07c160;
      color: #fff;
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
    }
    .btn.r {
      border: 1px solid #07c160;
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
    }
  }
}
.diyCell {
  width: 94%;
  margin-left: 3%;
  box-sizing: border-box;
  padding: 10px;
  padding-bottom: 0;
  border-radius: 5px;
  margin-top: 10px;
  background-color: #fff;
  box-shadow: 0 5px 5px #efefef;
  display: flex;
  flex-direction: column;
  .top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 6px;
    .title {
      flex: 1;
      font-weight: 600;
      font-size: 14px;
    }
  }
  p {
    // height: 48px;
    margin-bottom: 6px;
    font-size: 12px;
    color: #5f5f5f;
  }
  .time {
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid #f3f3f3;
    color: #b9b9b9;
    font-size: 12px;
  }
}
.row {
  width: 100%;
  min-height: 40px;
  box-sizing: border-box;
  display: flex;
  margin-bottom: 12px;
  align-items: center;
  .van-field {
    padding: 2px 4px;
  }
  &.row2 {
    align-items: center;
  }
  &.col {
    flex-direction: column;
    align-items: start;
    .van-field {
      border: 1px solid #eeeeee;
      border-radius: 5px;
      /* height: 100px; */
    }
    .label {
      margin-bottom: 10px;
    }
  }
  .label {
    width: 80px;
    flex-shrink: 0;
  }
  .label.must {
    position: relative;
  }
  .label.must::after {
    content: '*';
    color: #e10602;
    font-size: 18px;
    position: absolute;
    left: -8px;
    top: 1px;
  }
}
.van-radio-group--horizontal {
  justify-content: center;
}
.van-field {
  border: 1px solid #eeeeee;
  padding: 5px;
  border-radius: 5px;
}
.dialog-in {
  width: 100%;
  padding: 0 20px;
}
.dialog-in.star {
  margin: 10px 0;
  text-align: center;
}
.dialog-in.two {
  padding-top: 10px;
  max-height: 340px;
  overflow: auto;
  .row {
    align-items: start;
    .label {
      font-size: 12px;
      width: 40px;
      flex-shrink: 0;
    }
  }
}
</style>
